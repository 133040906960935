.namecard-logo {
    max-height: 100px;
}

.namecard-navlink-holder a {
    margin-left: 20px;
    color: var(--bs-gray);
    cursor: pointer;
    text-decoration: none;
}

.namecard-navlink-holder i {
    margin-right: 5px;
}

.profile-img {
    height: 200px;
    width: auto;
}

.namecard-bio {
    text-align: center;
    color: rgb(138,140,143);
}

.profile-details i {
    margin-right: 5px;
}

.profile-details a {
    text-decoration: none;
    color: #8a8c8f;
}

  