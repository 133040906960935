.home-title-with-image {
    height: 600px;
    background-image: url("../../public/assets/img/background-robot1.jpg");
    opacity: 0.9;
    background-position: 0% top;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
}

.home-title-with-image-careers {
    height: 600px;
    width: 100%;
    opacity: 0.9;
    position: relative;
    /* background-image: url("../../public/assets/img/background-careers-blur.png"); */
    background-repeat: repeat;
    background-size: contain;
}

.home-title-with-image-careers::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/assets/img/background-careers.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: blur(5px);
    z-index: -1;
}

.home-title-with-image-careers::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    background-image: url("../../public/assets/img/background-careers.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.our-services svg:hover, .our-services i:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 1s;
}

.floating-animation {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.background-careers-img {
    display: none;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}

@media screen and (max-width: 1085px) {
    .home-title-with-image-careers {
        /* height: 600px;
        background-image: url("../../public/assets/img/background-careers.jpg");
        opacity: 0.9;
        background-position: 0% top;
        background-size: contain;
        background-repeat: no-repeat; */
        display: none;
    }
    .home-title-with-image-careers::before {
        display: none;
    }
    .home-title-with-image-careers::after {
        display: none;
    }

    .background-careers-img {
        display: block;
        width: 100%;
        margin-top: 72px;
    }
}